import React from 'react';
import styles from './styles.module.css';
import androidStars from '../../../assets/svg/androidStars.svg';
import iosStars from '../../../assets/svg/iosStars.svg';
import { isIOS } from 'react-device-detect';

export default function Card({ person }) {
  const handleClick = () => window.location.href = 'https://app.adjust.com/o0bnzsf?campaign=buttons&adgroup=review&creative={click_referer}';

  return (
    <div className={styles.cardRoot} onClick={handleClick}>
      <img src={person.avatar} alt="avatar" />
      {isIOS ?
        <div className={styles.ios}>
          <img src={iosStars} alt="stars" className={styles.iosStars} />
          <div className={styles.iosNameDate}>{person.name}, {person.date2}</div>
          <div className={styles.title}>{person.title}</div>
          <div className={styles.comment}>{person.comment}</div>
        </div>
        :
        <div className={styles.android}>
          <div className={styles.androidWrapper}>
            <div className={styles.androidName}>{person.name}</div>
            <div className={styles.androidDate}>{person.date1}</div>
          </div>
          <img src={androidStars} alt="stars" className={styles.androidStars} />
          <div className={styles.comment}>{person.comment}</div>
        </div>
      }
    </div>
  );
}
