import React from 'react';
import styles from './styles.module.css';

export default function Footer() {
  return (
    <footer className={styles.footerRoot}>
      © Changelly 2015—2020
    </footer>
  );
}
