import React from 'react';
import styles from './styles.module.css';
import stars from '../../assets/svg/stars.svg';
import logo from '../../assets/svg/trustpilot-logo.svg';

export default function Rating() {
  return (
    <div className={styles.ratingRoot}>
      <img src={stars} alt="stars" />
      <span className={styles.text}>Excellent rating on</span>
      <img src={logo} alt="Trustpilot logo" />
    </div>
  );
}
