import spaceFrog from '../../../assets/svg/spaceFrog.svg';
import lego from '../../../assets/svg/lego.svg';
import blue from '../../../assets/svg/blue.svg';
import toy from '../../../assets/svg/toy.svg';

export default function useContent() {
  return [
    {
      avatar: spaceFrog,
      name: 'Aashish Goyal',
      date1: 'Jun 24, 2020 at 9:50 PM',
      date2: '06/24/2020',
      title: 'I love this app',
      comment: 'I love this app. Super easy to use and i use it a lot. And everytime it has given me better exchange rates compared to other similar apps...'
    },
    {
      avatar: lego,
      name: 'Juan Soriano',
      date1: 'Jul 28, 2020 at 6:40 AM',
      date2: '07/28/2020',
      title: 'fast and reliable',
      comment: "The best exchange experience, fast and reliable. I'm in love with the favorite addresses and destination tags, love it."
    },
    {
      avatar: blue,
      name: 'Suman Chakraborty',
      date1: 'Jun 5, 2020 at 3:19 AM',
      date2: '06/05/2020',
      title: 'I am very much satisfied',
      comment: "I am very much satisfied with the smooth working of the app.It is highly reliable and it has everything..."
    },
    {
      avatar: toy,
      name: 'Stefan Munteanu',
      date1: 'Apr 7, 2020 at 11:49 AM',
      date2: '04/07/2020',
      title: 'choose the pair',
      comment: "I couldn't find some of the altcoins on the exchanges I am using. Downloaded Changelly, choose the pair..."
    },
  ]
}
