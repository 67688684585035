import React from 'react';
import logoText from '../../assets/svg/logo_text.svg';
import logo from '../../assets/svg/logo.svg';
import styles from './styles.module.css';

export default function Header() {
  return (
    <header className={styles.headerRoot}>
      <img src={logoText} alt="changelly" className={styles.logoText} />
      <a href="https://app.adjust.com/o0bnzsf?campaign=buttons&adgroup=logo&creative={click_referer}" className={styles.logo}>
        <img src={logo} alt="logo" />
      </a>
    </header>
  );
}
