import React from 'react';
import styles from './styles.module.css';
import Rating from '../../components/Rating';
import Slider from '../../components/Slider';
import { isIOS } from 'react-device-detect';
import google from '../../assets/svg/google.svg';
import apple from '../../assets/svg/apple.svg';

export default function Main() {
  const handleClickGetBtn1 = () => (window.location.href = 'https://app.adjust.com/o0bnzsf?campaign=buttons&adgroup=astro&creative={click_referer}');
  const handleClickGetBtn2 = () => window.location.href = 'https://app.adjust.com/o0bnzsf?campaign=buttons&adgroup=two_astro&creative={click_referer}';
  const handleClickInstallBtn = () => window.location.href = 'https://app.adjust.com/o0bnzsf?campaign=buttons&adgroup=under_review&creative={click_referer}';

  return (
    <main className={styles.mainRoot}>
      <h1>Take the rocket <br /> to your pocket<span className={styles.dot}>.</span></h1>
      <p>Simplicity, 24/7 customer support <br /> and excellent service since 2015.</p>
      <div className={styles.bgPic1}>
        {isIOS ?
          <button className={`${styles.btn} ${styles.getBtn}`} onClick={handleClickGetBtn1}>
            <img src={apple} alt="apple" className={styles.getBtnLogo} />
          </button>
          :
          <button className={`${styles.btn} ${styles.getBtn}`} onClick={handleClickGetBtn1}>
            <img src={google} alt="google" className={styles.getBtnLogo} />
          </button>
        }
        <h1>Trusted by 2 million <br /> customers</h1>
        <Rating />
      </div>
      <Slider />
      <button className={`${styles.btn} ${styles.installBtn}`} onClick={handleClickInstallBtn}>
        Install
      </button>
      <div className={styles.bgPic2}>
        <div className={styles.wrapper}>
          <h1>You are never alone</h1>
          <p>Get 24/7 live support and feel secure <br /> with every moment you spend <br /> in the app.</p>
        </div>
      </div>
      {isIOS ?
        <button className={`${styles.btn} ${styles.getBtn2}`} onClick={handleClickGetBtn2}>
          <img src={apple} alt="apple" className={styles.getBtnLogo} />
        </button>
        :
        <button className={`${styles.btn} ${styles.getBtn2}`} onClick={handleClickGetBtn2}>
          <img src={google} alt="google" className={styles.getBtnLogo} />
        </button>
      }
    </main>
  );
}
