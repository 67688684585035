import React from 'react';
// import styles from './styles.module.css';
import Card from './Card';
import useContent from './helpers';
import Slider from "react-slick";

export default function Slick() {
  const list = useContent();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    variableWidth: true,
  };

  return (
      <Slider {...settings}>
        {list.map((person, i) => (
          <Card
            key={String(i)}
            person={person}
          />
        ))}
      </Slider>
  );
}
